import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"

export default function Projects({}){
  return (
    <div>
      <h3
        css={css`
          display: inline-block;
          border-bottom: 1px solid;
        `}
      >
        Projects
      </h3>
      <div>
        <a href="https://chrome.google.com/webstore/detail/flow/kjpaaejipgmhdheaiofekfgkkcehdndp" target='_blank'>Flow</a>
        <br/><br/>
        <Link to="/wikipedia-game">Wikipedia Game</Link>
        <br/><br/>
        <Link to="/kili-signup">கிளி (Kili)</Link>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`